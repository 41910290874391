 <template >
  <div>
    <Header />
    <div class="errore">
      <div class="errore-cont">
        <div class="errore-text">
          <h1>Oops!</h1>
          <h2>404</h2>
          <div class="errore-info">
            <p>
              No pudimos encontrar la página que buscas. No existe, se perdió,
              se fue.<br /><br />
              Intenta de nuevo.
            </p>
            <a href="/" class="btn-home">Ir al Home</a>
          </div>
        </div>
        <img
          src="../assets/sleepingcamaleon.gif"
          alt="chamaleon"
          class="camaleon-dormilon"
          width="1352"
          height="1034"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";
export default {
  name: "Error404",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      status: "error",
      message: "",
    };
  },
};
</script>
<style scoped>
.errore {
  width: 70.417vw;
  margin: 0 auto 11.719vw;
  padding-top: 15.99vw;
}
.errore-text {
  position: absolute;
  padding: 0 0 0 14.427vw;
}
.errore-cont h1 {
  font-family: "Gramatika-Medium";
  font-size: 4.167vw;
  line-height: 121.3%;
  color: #ffb6ea;
  margin: 0;
  font-weight: normal;
}
.errore-cont h2 {
  font-family: "Gramatika-Medium";
  font-size: 18.229vw;
  line-height: 18.229vw;
  color: #987ef3;
  margin: 0 0 0 1.198vw;
  position: relative;
  top: -2.969vw;
  font-weight: normal;
}
.errore-info {
  position: relative;
  width: 14.844vw;
  height: auto;
  margin-left: 29.74vw;
  margin-top: -5.3vw;
}
.errore-info p {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 1.302vw;
  line-height: 132.5%;
  color: #000000;
  margin: 0 0 1.979vw;
}
.btn-home {
  font-family: "Gramatika-Black";
  font-size: 1.302vw;
  line-height: 132.5%;
  color: #ffb6ea;
}
.camaleon-dormilon {
  padding-top: 19.792vw;
  width: 70.417vw;
  height: auto;
}

@media (max-width: 768px) {
  .errore {
    width: 100vw;
    margin: 0 auto 17.633vw;
    padding-top: 33.092vw;
  }
  .camaleon-dormilon {
    width: 100vw;
    padding-top: 74.638vw;
  }
  .errore-cont h1 {
    font-size: 7.246vw;
    line-height: 121.3%;
  }
  .errore-cont h2 {
    font-size: 36.232vw;
    line-height: 90.3%;
    top: -2.899vw;
  }
  .errore-info {
    position: relative;
    width: 72.222vw;
    height: auto;
    margin-left: 0;
    margin-top: 9.420vw;
  }
  .errore-info p {
    font-size: 2.899vw;
    margin: 0 0 6.039vw;
  }
  a.btn-home {
    font-size: 2.899vw;
  }
}
</style>